const MAXIMUM_IMAGE_BUFFER_COUNT = 10

const bufferAlgorithm = loadingCount => {
  if (loadingCount < MAXIMUM_IMAGE_BUFFER_COUNT - 3) {
    return 3
  } else {
    return MAXIMUM_IMAGE_BUFFER_COUNT - loadingCount
  }
}

export { MAXIMUM_IMAGE_BUFFER_COUNT }
export default bufferAlgorithm
