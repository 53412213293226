import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import Random from 'random-seed'

const CardLink = styled(Link)`
  margin: 40px 40px 0 40px;
  padding: 20px;
  box-shadow: 0 0 25px 0 #ccc;
  border-radius: 25px;
  line-height: 1.2;
  text-align: center;
  max-width: 500px;
  display: inline-block;
  cursor: pointer;

  & img {
    width: 80vw;
    max-width: 100%;
    max-height: 80vh;
    object-fit: cover; /* Only needed when height is clipped */
    padding-bottom: 15px;
  }
`

const H2 = styled.h4`
  font-family: Merriweather;
  font-style: normal;
  font-weight: 300;
  font-size: 17px;
  line-height: 1.6;
  color: var(--dark);
  margin: 0;
`

const Card = ({ link, headline, image }) => {
  const rotation = useMemo(() => {
    const random = Random.create(link)
    return random.floatBetween(-2, 2)
  }, [link])

  return (
    <CardLink to={`${link}`} style={{ transform: `rotate(${rotation}deg)` }}>
      {image}
      <H2 className="card-headline">{headline}</H2>
    </CardLink>
  )
}

Card.propTypes = {
  link: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  image: PropTypes.node,
}

Card.defaultProps = {
  image: null,
}

export default Card
