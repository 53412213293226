import React from 'react'
import { Link } from 'react-router-dom'
import Cards from '../Cards/Cards'
import styled from 'styled-components'

const HomeLogoWrapperDiv = styled.div`
  text-align: center;
`

const HomeLogoH1 = styled.h1`
  font-size: 90px;
  text-align: center;
  line-height: 1;
  margin-top: 45px;
  font-family: Courier, monospace;
  font-weight: 900;
  font-style: normal;
  color: transparent;
  display: inline-block;
  background: linear-gradient(var(--tan), var(--blush));
  background-clip: text;
  -webkit-background-clip: text;
`

const Home = () => {
  return (
    <>
      <Link to="/">
        <HomeLogoWrapperDiv>
          <HomeLogoH1>DMS</HomeLogoH1>
        </HomeLogoWrapperDiv>
      </Link>
      <Cards />
    </>
  )
}

export default Home
