import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Disables server rendering for its children
// https://www.joshwcomeau.com/react/the-perils-of-rehydration/
const ClientOnly = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])
  if (!hasMounted) {
    return null
  }
  return children
}

ClientOnly.propTypes = {
  children: PropTypes.node,
}

export default ClientOnly
