import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import styled from 'styled-components'
import DomNode from '../DomNode'
import Button from '../Button'
import useNextImage from './useNextImage'

const Container = styled.div`
  width: 100%;
  height: 100%;
`

const ImageContainer = styled.div`
  cursor: pointer;

  &,
  & > *,
  img {
    /* Can't use vh due to iOS */
    width: 100%;
    height: 100%;
  }
  img {
    object-fit: contain;
    object-position: center center;
    display: block;
  }
`

const Loading = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const LoadingInner = styled.div`
  color: white;
  font-size: 18px;
`

const Lolrandom = () => {
  const { imageElement, isLoading, isStuck, getNext } = useNextImage()

  useEffect(() => {
    const keyUpHandler = event => {
      if ([' ', 'Enter', 'ArrowRight', 'ArrowDown'].includes(event.key)) {
        getNext()
      }
    }
    window.addEventListener('keyup', keyUpHandler)
    return () => {
      window.removeEventListener('keyup', keyUpHandler)
    }
  }, [])

  return (
    <Container>
      <Helmet>
        <title>Lolrandom</title>
        <style type="text/css">
          {`
          body {
            background-color: black;
          }
          `}
        </style>
      </Helmet>
      {isLoading ? (
        <Loading>
          <LoadingInner>Loading...</LoadingInner>
        </Loading>
      ) : null}
      {isStuck ? (
        <Loading>
          <Button onClick={getNext}>Retry</Button>
        </Loading>
      ) : null}
      {!(isLoading || isStuck) ? (
        <ImageContainer onClick={getNext}>
          <DomNode>{imageElement}</DomNode>
        </ImageContainer>
      ) : null}
    </Container>
  )
}

export default Lolrandom
