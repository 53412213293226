// import PropTypes from 'prop-types'

// const articlePropTypes = PropTypes.shape({
//   slug: PropTypes.string.isRequired,
//   title: PropTypes.string.isRequired,
//   author: PropTypes.string.isRequired,
//   published: PropTypes.string.isRequired,
//   image: PropTypes.string,
//   content: PropTypes.string
// }).isRequired

// export default articlePropTypes
export default {}
