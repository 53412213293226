import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './components/App'

const articles = window.articles
delete window.articles

ReactDOM.hydrateRoot(
  document.getElementById('root'),
  <BrowserRouter>
    <App articles={articles} />
  </BrowserRouter>,
)
