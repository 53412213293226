import { useRef, useState, useLayoutEffect, useCallback, useMemo } from 'react'
import { produce } from 'immer'

const useFlow = ({ initialState, watched, actions: actionsConfig }) => {
  const { unmountable, wrapAction } = useUnmountable()
  const [produceNewStateChangeCount, setProduceNewStateChangeCount] = useState(0)

  const watchedRef = useRef(watched)
  const stateRef = useRef(initialState)

  const getWatched = () => watchedRef.current
  const getState = () => stateRef.current

  useLayoutEffect(() => {
    watchedRef.current = watched
  })

  const setState = newState => {
    if (Object.keys(newState).length !== Object.keys(stateRef.current).length) {
      throw new Error('The initialState object must include all properties you intend to use.')
    }
    stateRef.current = newState
    setProduceNewStateChangeCount(count => count + 1)
  }

  const produceNewState = stateProducer => {
    setState(produce(getState(), stateProducer))
  }

  // Enables nesting, i.e. actions.updateUser() can trigger actions.clearUser() via the argument
  const actions = {}
  const actionArguments = {
    getState,
    getWatched,
    produceNewState,
    unmountable,
    actions,
  }
  const createdActions = actionsConfig(actionArguments)
  Object.keys(createdActions).forEach(key => {
    actions[key] = wrapAction(createdActions[key])
  })

  // Without memoization, both the state and actions would appear to have changed every time
  // a useFlow component or hook renders. See useCallback and useMemo docs for more information.
  const memoizedState = useMemo(() => stateRef.current, [produceNewStateChangeCount])
  const memoizedActions = useMemo(() => actions, [])

  return { state: memoizedState, actions: memoizedActions }
}

class UseFlowUnmountError extends Error {
  constructor() {
    super()
    this.name = 'UseFlowUnmountError'
  }
}

const useUnmountable = () => {
  const isMounted = useRef(true)

  useLayoutEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const unmountable = useCallback(promise => {
    const wrappedPromise = promise.then(result => {
      if (!isMounted.current) throw new UseFlowUnmountError()
      return result
    })

    wrappedPromise.originalCatch = wrappedPromise.catch
    wrappedPromise.catch = errorHandler => {
      return wrappedPromise.originalCatch(error => {
        if (error.name === 'UseFlowUnmountError') throw error
        return errorHandler(error)
      })
    }

    return wrappedPromise
  }, [])

  const wrapAction = action => {
    return (...args) => {
      const response = action(...args)
      if (!(response && response.catch)) return response

      response.catch(error => {
        if (error.name === 'UseFlowUnmountError') return
        throw error
      })

      return response
    }
  }

  return { unmountable, wrapAction }
}

export default useFlow
