import React, { useContext } from 'react'
import { ArticleContext } from '../ArticleProvider'
import styled from 'styled-components'
import Card from '../Card'
import ClientOnly from '../ClientOnly'
import LolrandomCard from '../LolrandomCard'

const WrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 50px;
`

const Cards = () => {
  const articles = useContext(ArticleContext)

  if (!articles) {
    return null
  }

  const cards = articles.map((article, index) => (
    <Card
      key={article.slug}
      link={`/${article.slug}`}
      headline={article.title}
      isFirst={index === 0}
    />
  ))

  return (
    <WrapperDiv>
      {cards}
      <ClientOnly>
        <LolrandomCard />
      </ClientOnly>
    </WrapperDiv>
  )
}

export default Cards
