import React, { useLayoutEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const DomNode = ({ children: node }) => {
  const container = useRef()

  useLayoutEffect(() => {
    container.current.innerHTML = ''
    container.current.appendChild(node)
  }, [node])

  return <div ref={container} />
}

DomNode.propTypes = {
  children: PropTypes.object,
}

export default DomNode
