import React, { createContext, useEffect, useState } from 'react'

const ArticleContext = createContext()

// const useArticle = (slug, { includesContent = false } = {}) => {
//   const articles = useContext(ArticleContext)
//   const article = articles.find(eachArticle => eachArticle.slug === slug)

//   if (includesContent && !article.content) {
//     return null
//   }

//   return article
// }

const ArticleProvider = ({ initialArticles, children }) => {
  const [articlesWithContent, setArticlesWithContent] = useState(initialArticles)

  useEffect(() => {
    Promise.all(
      articlesWithContent.map(async (article) => {
        const response = await fetch(
          'https://public-api.wordpress.com/rest/v1.1/sites/dms747120355.wordpress.com' +
            `/posts/${article.id}/?fields=ID,content`
        )
        return response.json()
      })
    ).then((contentOfArticles) => {
      const newArticlesWithContent = articlesWithContent.map((article) => {
        const { content } = contentOfArticles.find((content) => content.ID === article.id)
        return { ...article, content }
      })
      setArticlesWithContent(newArticlesWithContent)
    })
  }, [])

  return <ArticleContext.Provider value={articlesWithContent}>{children}</ArticleContext.Provider>
}

export { ArticleContext }
export default ArticleProvider
