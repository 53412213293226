import React from 'react'
import { Helmet } from 'react-helmet'
import ArticleProvider from '../ArticleProvider'
import InitialImageProvider from '../LolrandomCard/InitialImageProvider'
import Routes from '../Routes'
import useAnalytics from './useAnalytics'
import './App.css'
import { useLocation } from 'react-router-dom'

const App = ({ articles }) => {
  const location = useLocation()
  useAnalytics({ location })

  return (
    <ArticleProvider initialArticles={articles}>
      <InitialImageProvider>
        <Helmet>
          <title>DMS</title>
        </Helmet>
        <Routes />
      </InitialImageProvider>
    </ArticleProvider>
  )
}

export default App
