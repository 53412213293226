import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import { Helmet } from 'react-helmet'
import { faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ArticleContext } from '../ArticleProvider'
import Button from '../Button'
import Home from '../Home'

const ControlsWrapperDiv = styled.div`
  margin-top: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

const AuthorDiv = styled.div`
  color: #555;
  font-size: 16px;
  font-family: 'Roboto';
  font-weight: 700;
  margin-left: 12px;
  span {
    display: inline-block;
  }
`

const DividerDiv = styled.div`
  background: linear-gradient(to right, var(--tan), var(--blush));
  width: 60%;
  height: 2px;
  margin: 0 auto 25px;
  border-radius: 1px;
  margin-bottom: 8vh;
`

const Article = ({ slug }) => {
  const articles = useContext(ArticleContext)

  if (!articles) {
    return null
  }

  const article = articles.find((eachArticle) => eachArticle.slug === slug)

  if (!article.content) {
    return null
  }

  if (!article) {
    return 'Article not found'
  }

  return (
    <>
      <Helmet>
        <title>{article.title}</title>
      </Helmet>
      <div className="wrapped-content">
        <ControlsWrapperDiv>
          <Link to="/">
            <Button>
              <FontAwesomeIcon icon={faLongArrowAltLeft} /> Home
              {/* Home */}
            </Button>
          </Link>
          <div>
            <AuthorDiv>
              <span>By {article.author}&nbsp;∙&nbsp;</span>
              <span>{article.published}</span>
            </AuthorDiv>
          </div>
        </ControlsWrapperDiv>
      </div>
      <article className="article">
        <h1>{article.title}</h1>
        <DividerDiv />
        {parse(article.content)}
      </article>
      <Home />
    </>
  )
}

Article.propTypes = {
  slug: PropTypes.string.isRequired,
}

export default Article
