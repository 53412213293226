import React, { useEffect, useRef } from 'react'
import Home from '../Home'
import Article from '../Article'
import { Switch, Route, useLocation } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Lolrandom from '../Lolrandom'
import ClientOnly from '../ClientOnly'

const ScrollToTop = ({ children }) => {
  const { pathname } = useLocation()
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      window.scrollTo(0, 0)
    } else {
      didMountRef.current = true
    }
  }, [pathname])

  return children
}

const Routes = () => (
  <ScrollToTop>
    <Switch>
      <Route
        exact
        path="/"
        render={() => (
          <>
            <Helmet>
              <meta
                name="description"
                content={
                  'Everything from how to pick a lock to how to fill a balloon with your own poop ' +
                  '(coming soon!)'
                }
              />
            </Helmet>
            <Home />
          </>
        )}
      />
      <Route
        path="/lolrandom"
        render={() => (
          <ClientOnly>
            <Lolrandom />
          </ClientOnly>
        )}
      />
      <Route path="/:slug" render={({ match }) => <Article slug={match.params.slug} />} />
    </Switch>
  </ScrollToTop>
)

export default Routes
