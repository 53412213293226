import React, { useContext, useEffect, useState } from 'react'
import { InitialImageContext } from './InitialImageProvider'
import DomNode from '../DomNode'
import Card from '../Card'
import loadImageElement from '../Lolrandom/useNextImage/loadImageElement'

const LolrandomCard = () => {
  const [imageElement, setImageElement] = useState()
  const { setInitialImage } = useContext(InitialImageContext)

  useEffect(() => {
    loadImageElement().then(imageElement => {
      setImageElement(imageElement)
      setInitialImage(imageElement)
    })
  }, [])

  if (!imageElement) {
    return null
  }

  return <Card link="/lolrandom" headline="Lolrandom" image={<DomNode>{imageElement}</DomNode>} />
}

export default LolrandomCard
