import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const ButtonElement = styled.button`
  all: unset;
  display: block;
  background: #fff;
  border: 4px solid #eee;
  padding: 5px 8px;
  border-radius: 30px;
  color: #555;
  cursor: pointer;
  transition: background-color 50ms linear, border-color 50ms linear;
  font-family: 'Roboto';
  font-weight: 700;
  white-space: nowrap;

  &:hover {
    background: #eee;
    border-color: #e0e0e0;
  }
  &:active {
    background: #c0c0c0;
    border-color: #ccc;
  }
`

const Button = ({ children, ...props }) => {
  return (
    <ButtonElement className="button" type="button" {...props}>
      {children}
    </ButtonElement>
  )
}

Button.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Button
